import request from "@/utils/request"

//查询活动列表一览
export function activitylist(data) {
  return request({
    url: "/activity/list",
    method: "post",
    data
  })
}
//修改活动冻结/解冻状态
export function updateFreeze(query) {
  return request({
    url: "/activity/updateFreeze",
    method: "get",
    params: query
  })
}
//修改活动上下架状态
export function updateShelf(data) {
  return request({
    url: "/activity/updateShelf",
    method: "post",
    data
  })
}
//新增活动
export function addActivity(data) {
  return request({
    url: "/activity/add",
    method: "post",
    data
  })
}
// 获取视频url和封面图片
export function getVideo(query) {
  return request({
    url: "/activity/getVideo",
    method: "get",
    params: query
  })
}


//编辑活动
export function editActivity(data) {
  return request({
    url: "/activity/edit",
    method: "post",
    data
  })
}
//获取活动详情
export function selectById(query) {
  return request({
    url: "/activity/getInfo",
    method: "get",
    params: query
  })
}
// 小程序-活动详情
export function getAppInfo(query) {
  return request({
    url: "/activity/getAppInfo",
    method: "get",
    params: query
  })
}
//获取活动表单详情
export function selectActivityFormById(query) {
  return request({
    url: "/activityForm/selectActivityFormById",
    method: "get",
    params: query
  })
}
//新增活动表单
export function insertActivityForm(data) {
  return request({
    url: "/activityForm/insertForm",
    method: "post",
    data
  })
}
//编辑活动表单
export function updateActivityForm(data) {
  return request({
    url: "/activityForm/updateForm",
    method: "post",
    data
  })
}
// 查询活动报名一览
export function selectActivitySignUp(data) {
  return request({
    url: "/activity/selectActivitySignUp",
    method: "post",
    data
  })
}
//查看用户报名表单的信息
export function selectByActivityId(data) {
  return request({
    url: "/activityForm/selectByActivityId",
    method: "post",
    data
  })
}
//审核拒绝-用户报名活动
export function refuseUserRegisterActivity(query) {
  return request({
    url: "/activity/refuseUserRegisterActivity",
    method: "get",
    params: query
  })
}
//审核通过
export function auditUserRegisterActivity(query) {
  return request({
    url: '/activity/auditUserRegisterActivity',
    method: 'get',
    params: query

  })
}
//用户签到-用户报名活动
export function userSignIn(query) {
  return request({
    url: "/activity/userSignIn",
    method: "get",
    params: query
  })
}
//用户提交报名信息 --- 后台
export function userPushRegister(data) {
  return request({
    url: '/activeOrder/backstageSubscription',
    method: 'post',
    data
  })
}
//计算总票价
export function calculatedAmount(data) {
  return request({
    url: '/activity/calculatedAmount',
    method: 'post',
    data
  })
}
//批量导出活动用户报名数据
export function exportExcel(data) {
  return request({
    url: '/activity/exportExcel',
    method: 'post',
    responseType: "blob",
    data
  })
}
// /activity/exportExcel  批量导出活动用户报名数据
// /activityForm/export 活动表单的导出

//查询行业列表（不带分页）
export function selectIndustry(query) {
  return request({
    url: "/activity/selectIndustry",
    method: "get",
    params: query
  })
}

//生成专题二维码
export function basicQrcode(query) {
  return request({
    url: "/activity/basicQrcode",
    method: "get",
    responseType: "blob",
    params: query
  })
}
// 查询列数和排数
export function selectManySeats(query) {
  return request({
    url: "/seat/getById",
    method: "get",
    params: query
  })
}
//新增/修改座位 总
export function addSetSeats(data) {
  return request({
    url: '/seat/add',
    method: 'post',
    data
  })
}
// 查询活动报名成功用户列表
export function selectSignUp(data) {
  return request({
    url: "/seat/selectSignUp",
    method: "post",
    data
  })
}
// 批量导入座位配置 excel
export function importSeat(data) {
  return request({
    url: "/seat/importSeat",
    method: "post",
    data
  })
}
// 编辑座位（单个）
export function updateOneSeat(data) {
  return request({
    url: '/seat/update',
    method: 'post',
    data
  })
}
// 查询座位排列情况
export function selectSeatsList(query) {
  return request({
    url: "/seat/selectList",
    method: "get",
    params: query
  })
}

// 查询活动的所有报名记录数量
export function registrationRecordsNumber(query) {
  return request({
    url: "/activityForm/registrationRecordsNumber",
    method: "get",
    params: query
  })
}

//模糊搜索企业
export function selectCompanyByName(query) {
  return request({
    url: "/company/selectByName",
    method: "get",
    params: query
  })
}

//模糊搜索商会
export function selectChamberCommerceByName(query) {
  return request({
    url: "/commerce/listTwo",
    method: "get",
    params: query
  })
}
